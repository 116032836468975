// @flow

import React from 'react';
import { MdWarning, MdStar } from 'react-icons/md';
import validator from '../../utils/validator';
import styles from './text.module.scss';

type Props = {
  // validation?: boolean,
  label: string,
  // enableShake?: boolean,
  id: string,
  name: string,
  onBlur: Function,
  onChange: Function,
  textArea?: boolean,
  required?: boolean,
  registError: Function,
  unregistError: Function,
  validations?: Array<any>,
};

type State = {
  value: string,
  error: boolean,
  errorMessage: string,
};

export default class Text extends React.Component<Props, State> {
  static defaultProps = {
    textArea: false,
    required: true,
    onBlur: () => {},
    enableShake: false,
    validation: false,
    onChange: () => {},
    registError: () => {},
    unregistError: () => {},
    validations: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: '',
      error: false,
      errorMessage: '',
    };
  }

  handleOnChange = (e: any) => {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.onChange(e);
      }
    );
    this.handleValidation(e);
  };

  handleOnBlur = (e: any) => {
    this.props.onBlur(e);
    this.handleValidation(e);
  };

  validate = (validation: Object, e: Object) => {
    const isValid = validator[validation.rule](e.target.value);
    if (isValid === false) {
      this.setState({ error: true, errorMessage: validation.errorMessage });
      this.props.registError(e.target.name);
    } else {
      this.setState({ error: false, errorMessage: '' });
      this.props.unregistError(e.target.name);
    }
    return isValid;
  };

  handleValidation = (e: any) => {
    const isValid =
      this.props.validations && this.props.validations.every(validation => this.validate(validation, e), this);
    // if (isValid) {
    //   this.props.unregistError(e);
    // } else {
    //   this.props.registError(e);
    // }
    console.log(isValid);
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.textWrapper} ${this.state.error ? styles.errorWrapper : ''}`}>
          <label htmlFor={this.props.id}>
            {this.props.label}
            {this.props.required && (
              <span className={styles.iconStar}>
                <MdStar />
              </span>
            )}
          </label>
          {this.props.textArea ? (
            <textarea
              id={this.props.id}
              name={this.props.name}
              value={this.state.value}
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
              className={`${styles.textarea} ${this.state.error ? styles.onShake : ''}`}
            />
          ) : (
            <input
              className={`${styles.input} ${this.state.error ? styles.onShake : ''}`}
              id={this.props.id}
              name={this.props.name}
              value={this.state.value}
              type="text"
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
            />
          )}
          <span className={styles.border} />
        </div>
        {this.state.error && (
          <span className={styles.error}>
            <MdWarning />
            {this.state.errorMessage}
          </span>
        )}
      </div>
    );
  }
}
