// @flow

import React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import { FaAnchor } from 'react-icons/fa';
import styles from './gMap.module.scss';
import APIs from '../../../constants/api';

type Props = {
  marker: Object,
};

type State = {
  showInfoWindow: boolean,
};

export default class CustomMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  toggle = () => {
    this.setState({
      showInfoWindow: !this.state.showInfoWindow,
    });
  };

  render() {
    const { pref, url, addr } = this.props.marker;
    const options = {
      icon: {
        url: `${APIs.uploadedImg}2019/06/3d-buildings-g.svg`,
      },
    };
    return (
      <div>
        <Marker
          options={options}
          onClick={this.toggle}
          position={{ lat: this.props.marker.lat, lng: this.props.marker.lng }}
        >
          {this.state.showInfoWindow ? (
            <InfoWindow onCloseClick={this.toggle}>
              <ul className={styles.info}>
                <li>
                  <i>
                    <FaAnchor />
                  </i>
                  {pref}
                </li>
                <li>{addr}</li>
                <li>
                  <a href={url} target="_blank" rel="noreferrer noopener">
                    {pref}のHPへ
                  </a>
                </li>
              </ul>
            </InfoWindow>
          ) : null}
        </Marker>
      </div>
    );
  }
}
