// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

type Props = {
  onClick?: Function,
  to?: string,
  query?: Object,
  label: string,
  imgSrc?: string,
  imgAlt?: string,
  theme?: string,
  disable?: boolean,
  svgIcon?: any,
};

type State = {
  onHover: boolean,
};

export default class Button extends React.Component<Props, State> {
  static defaultProps = {
    disable: false,
    href: '',
    query: '',
    imgSrc: '',
    imgAlt: '',
    theme: 'primary',
    svgIcon: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      onHover: false,
    };
  }

  handleHoverOn = () => {
    this.setState({
      onHover: true,
    });
  };

  handleHoverOff = () => {
    this.setState({
      onHover: false,
    });
  };

  render() {
    const buttonTheme = this.props.theme === 'secondary' ? styles.secondary : '';

    const buttonContents = () => (
      <button
        type="button"
        className={`${styles.button} ${this.state.onHover ? styles.onHover : ''} ${buttonTheme} ${
          this.props.disable ? styles.disable : ''
        }`}
        onMouseEnter={this.handleHoverOn}
        onMouseLeave={this.handleHoverOff}
        onClick={this.props.onClick || null}
      >
        <span>{this.props.label}</span>
        {this.props.imgSrc && <img className={styles.iconWrapper} src={this.props.imgSrc} alt={this.props.imgAlt} />}
        {this.props.svgIcon && <div className={styles.svgIconWrapper}>{this.props.svgIcon}</div>}
      </button>
    );
    return this.props.to ? (
      <Link to={this.props.to}>
        <a href>{buttonContents()}</a>
      </Link>
    ) : (
      buttonContents()
    );
  }
}
