import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import fetchPrefectures from './fetchPrefectures';
import posts from './posts';
import categories from './categories';
import medias from './medias';
import gmail from './gmail';

export default combineReducers({
  simpleReducer,
  fetchPrefectures,
  posts,
  categories,
  medias,
  gmail,
});
