// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './readMore.module.scss';

type Props = {
  pathname: string,
  query: {
    id: string,
  },
  linkText?: string,
  textWhite?: boolean,
};

export default function ReadMore(props: Props) {
  return (
    // Example: href={{ pathname: 'post', query: { id: p.id } }}
    <Link to={{ pathname: props.pathname, query: { id: props.query.id } }}>
      <a href className={`${styles.link} ${props.textWhite ? styles.textWhite : ''}`}>
        <span className={styles.bar} />
        {props.linkText}
      </a>
    </Link>
  );
}

ReadMore.defaultProps = {
  linkText: 'もっと読む',
  query: {
    id: '',
  },
  textWhite: false,
};
