// @flow

import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Breadcrumb from '../../components/breadcrumb';
import styles from './postPage.module.scss';

type Props = {
  post: Object,
  fetchPost: Function,
};

export default class PostPage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPost();
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.contents}>
          <Breadcrumb type="post" name={this.props.post.title} />
          {this.props.post ? (
            <div>
              <h1 className={styles.h1}>{ReactHtmlParser(this.props.post.title)}</h1>
              <p>{ReactHtmlParser(this.props.post.content)}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
