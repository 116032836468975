// @flow
type ActionOrNothing = any | { type: '' };

const initialState = {
  items: [],
  errors: [],
  isFetching: false,
};

export default function reducer(state: any = initialState, action: ActionOrNothing = { type: '' }) {
  switch (action.type) {
    case 'FETCH_MEDIA_BEGIN':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'FETCH_MEDIA_SUCCESS':
      return Object.assign({}, state, {
        items: action.response,
      });
    case 'FETCH_MEDIA_ERROR':
      return Object.assign({}, state, {
        errors: action.response.errors,
        isFetching: false,
      });
    default:
      return state;
  }
}
