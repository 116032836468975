// @flow

import React from 'react';
import { connect } from 'react-redux';
import uniq from 'lodash.uniq';
import { MdSend } from 'react-icons/md';
import Breadcrumb from '../../components/breadcrumb';
import Button from '../../components/button';
import Text from '../../components/text';
import styles from './service.module.scss';

type Props = {};

type State = {
  name: string,
  address: string,
  tel: string,
  budget: string,
  content: string,
  errors: Array<any>,
};

export class Service extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      tel: '',
      budget: '',
      content: '',
      errors: ['name', 'address', 'tel', 'budget', 'content'],
    };
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.contents}>
          <div className={styles.head}>
            <Breadcrumb type="service" />
            <div className={styles.title}>サービス紹介</div>
            <p className={styles.subTitle}>
              ○○には、自然と何かが生まれる文化がある。2000年の設立より、300名を超える仲間とともに、自由な発想と行動力で仕事の枠にとらわれることなく、楽しむこと、突き詰めること、チャレンジすること。様々な活動が生まれています。
            </p>
          </div>
          <section className={styles.section}>
            <div className={styles.picTitleWrapper}>
              <div className={styles.picTitle}>
                <h1>ホームページ・デザイン・コンサルティング</h1>
                <p>Home page / design / consulting</p>
              </div>
            </div>
            <div className={styles.contents}>
              <div className={styles.detail}>
                <div className={styles.img}>
                  <div className={styles.titleBg}>
                    <p className={styles.subTitle}># 01</p>
                    <h2 className={styles.title}>ホームページ制作</h2>
                  </div>
                </div>
                <p>ホームページのデザイン制作、コーディングを代行いたします。</p>
                <p className={styles.comment}>
                  お客様にご準備頂くもの
                  <ul>
                    <li>コンテンツ(文章)、写真・イラストなどの素材</li>
                    <li>ホームページの基本コンセプト・アイデアなど</li>
                    <li>ホームページを設置するためのレンタルサーバー</li>
                  </ul>
                  <span className={styles.caution}>※ 弊社にて準備する場合は、別途費用が発生することがあります。</span>
                </p>
                <p>
                  制作の流れ：
                  <br />
                  １．コンテンツ(文章)や、写真・イラストなどの素材はお客様にてご用意いただきます。
                  <br />
                  ※写真撮影などを必要とする場合は、別途追加料金をいただきます。
                  <br />
                  ２．コンセプトや実現したいアイデアをお伺いします。Webデザインが必要なお客様は、弊社専属のデザイナーによるデザインをご提供します。
                  <br />
                  ３. デザインをもとに、コーディング制作を開始します。
                  <br />
                  4.
                  制作物をご確認いただき、問題なければサーバーへのアップロード、あるいはソースコードの提供をもって納品とさせていただきます。
                </p>
              </div>
            </div>
            <hr />
            <div className={styles.contents}>
              <div className={styles.detail}>
                <div className={styles.img2}>
                  <div className={styles.titleBg}>
                    <p className={styles.subTitle}># 02</p>
                    <h2 className={styles.title}>Webシステムプログラミング</h2>
                  </div>
                </div>
                <p>
                  コーディング及び、システム構築のサービスをご提供します。
                  <br />
                  基本的にはフロントエンド（ユーザーインターフェース部分）を対応範囲としております。
                </p>
                <p className={styles.comment}>
                  対応可能な技術
                  <ul>
                    <li>Wordpressのテンプレート作成・カスタマイズ</li>
                    <li>jQuery・JavaScriptを使用した動的なインタラクティブWebサイトの作成</li>
                    <li>React.jsでのシングルページアプリケーション作成</li>
                    <li>Flow、Eslint、Webpackなどフロントエンド向けの開発環境の構築</li>
                    <li>node.jsでのフロントエンド向けAPI構築</li>
                    <li>node.js、next.jsを使用したサーバーサイドレンダリングアプリケーション作成</li>
                    <li>Ruby on Railを使用したアプリケーションのフロントエンド対応</li>
                    <li>レンタルサーバーの設定、構築、運用等</li>
                  </ul>
                </p>
                <p>スタートアップ企業様向けの開発サポートや、クラウドソーシング向けのリモート開発なども承ります。</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export function mapDispatchToProps(/* dispatch */) {
  return {};
}

export function mapStateToProps(/* state: any,  ownProps: Prop */) {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Service);
