import * as React from 'react';
import Chart from 'react-google-charts';
import Breadcrumb from '../../components/breadcrumb';
import styles from './chartPage.module.scss';

const GeoData = [
  ['Country', 'Popularity'],
  ['Germany', 200],
  ['United States', 300],
  ['Brazil', 400],
  ['Canada', 500],
  ['France', 600],
  ['RU', 700],
  ['Japan', 800],
];

const GeoData2 = [
  ['Country', 'Population'],
  ['Afghanistan', 37412.407],
  ['Armenia', 2958.4120000000003],
  ['Azerbaijan', 10090.201],
  ['Bahrain', 1660.714],
  ['Bangladesh', 169348.40300000002],
  ['Bhutan', 832.44],
  ['Brunei Darussalam', 442.621],
  ['Cambodia', 16573.048],
  ['China', 1429470.8390000002],
  ['Cyprus', 1207.403],
  ['Georgia', 3934.593],
  ['India', 1378419.072],
  ['Indonesia', 271429.286],
  ['Iraq', 40664.756],
  ['Israel', 8637.271],
  ['Japan', 127505.823],
  ['Jordan', 10149.162],
  ['Kazakhstan', 18720.881],
  ['Kuwait', 4266.867],
  ['Kyrgyzstan', 6265.68],
  ["Lao People's Democratic Republic", 7120.714],
  ['Lebanon', 6112.049],
  ['Malaysia', 32682.583],
  ['Maldives', 455.05699999999996],
  ['Mongolia', 3189.888],
  ['Myanmar', 54720.683],
  ['Nepal', 30181.886000000002],
  ['Oman', 5046.221],
  ['Pakistan', 205971.509],
  ['Philippines', 108851.814],
  ['Qatar', 2749.8559999999998],
  ['Saudi Arabia', 34363.863],
  ['Singapore', 5911.271],
  ['Sri Lanka', 21159.539],
  ['Syrian Arab Republic', 18447.116],
  ['Tajikistan', 9360.584],
  ['Thailand', 69740.431],
  ['Timor-Leste', 1359.457],
  ['Turkey', 83695.85],
  ['Turkmenistan', 5987.536],
  ['United Arab Emirates', 9732.176],
  ['Uzbekistan', 33072.895],
  ['Viet Nam', 98135.145],
  ['Yemen', 29776.353],
];

const popData = [
  ['Country', '1980 Population', '2019 Population', '2050 Population'],
  ['Japan', 117827.355, 127505.823, 118774.89],
  ['Viet Nam', 54372.514, 98135.145, 127089.409],
  ['Philippines', 47396.968, 108851.814, 169513.807],
  ['Thailand', 47385.323, 69740.431, 72708.412],
];

const stockData = [
  ['日付', '終値', '始値', '高値', '安値'],
  ['2017/1/1', 18787.99, 19594.16, 19041.34, 19594.16],
  ['2017/2/1', 18907.67, 19148.08, 19118.99, 19459.15],
  ['2017/3/1', 18909.26, 19393.54, 18909.26, 19633.75],
  ['2017/4/1', 18335.63, 18983.23, 19196.74, 19289.43],
  ['2017/5/1', 19310.52, 19310.52, 19650.57, 19961.55],
  ['2017/6/1', 19831.82, 19860.03, 20033.43, 20230.41],
  ['2017/7/1', 19925.18, 20055.8, 19925.18, 20195.48],
  ['2017/8/1', 19353.77, 19985.79, 19646.24, 20080.04],
  ['2017/9/1', 19274.82, 19691.47, 20356.28, 20397.58],
  ['2017/10/1', 20400.78, 20400.78, 22011.61, 22011.67],
  ['2017/11/1', 22028.32, 22420.08, 22724.96, 22937.6],
  ['2017/12/1', 22177.04, 22819.03, 22764.94, 22939.18],
  ['2018/1/1', 23098.29, 23506.33, 23098.29, 24124.15],
  ['2018/2/1', 21154.17, 23486.11, 22068.24, 23486.11],
  ['2018/3/1', 20617.86, 21724.47, 21454.3, 21968.1],
  ['2018/4/1', 21292.29, 21388.58, 22467.87, 22467.87],
  ['2018/5/1', 22018.52, 22508.03, 22201.82, 23002.37],
  ['2018/6/1', 22171.35, 22171.35, 22304.51, 22966.38],
  ['2018/7/1', 21546.99, 21811.93, 22553.72, 22794.19],
  ['2018/8/1', 21857.43, 22746.7, 22865.15, 22869.5],
  ['2018/9/1', 22307.06, 22707.38, 24120.04, 24120.04],
  ['2018/10/1', 21149.8, 24245.76, 21920.46, 24270.62],
  ['2018/11/1', 21507.54, 21687.65, 22351.06, 22486.92],
  ['2018/12/1', 19155.74, 22574.76, 20014.77, 22574.76],
  ['2019/1/1', 19561.96, 19561.96, 20773.49, 20773.56],
  ['2019/2/1', 20333.17, 20788.39, 21385.16, 21556.51],
  ['2019/3/1', 20977.11, 21602.69, 21205.81, 21822.04],
  ['2019/4/1', 21505.31, 21509.03, 22258.73, 22307.58],
  ['2019/5/1', 20601.19, 21923.72, 20601.19, 21923.72],
  ['2019/6/1', 20408.54, 20410.88, 21116.89, 21204.28],
];

// Wrapper with Google Map component
export default class ChartPage extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <Breadcrumb type="chart" />
          <h1 className={styles.title}>図表関連アプリ 作成・カスタマイズ</h1>
          <p className={styles.subTitle}>
            ここでは、政府や公的機関が運用している統計情報のAPIデータを、Javascriptを使ってグラフに表現したサンプルWebアプリをご紹介しています。アイデア次第ですが、データソースをインタラクティブに変化させることで、ダイナミックに変化する動的なグラフ表現なども自由に組み込むことが出来ます。
          </p>
        </div>
        <hr />
        <div className={styles.contents}>
          <h2 className={styles.title}>エリア別MAPチャート</h2>
          <div className={styles.chart}>
            <div className={styles.sampleView}>
              <Chart
                // https://developers.google.com/chart/interactive/docs/gallery/geochart
                chartType="GeoChart"
                width="95%"
                height="270px"
                data={GeoData2}
                loader={<div>Loading Chart....</div>}
                options={{
                  // displayMode: 'markers',
                  // https://developers.google.com/chart/interactive/docs/gallery/geochart#Continent_Hierarchy
                  // region: 'world',
                  region: '142',
                  animation: {
                    startup: true,
                    easing: 'linear',
                    duration: 1500,
                  },
                  // colorAxis: { colors: ['green', 'Red'] },
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
            <p>
              2019年の人口を、アジア地域の国別にマッピング（1,000人単位）したチャートです。
              <br />
              総務省統計局の世界の推計人口をデータソースとしています。
              <br />
              (参照: https://www.stat.go.jp/data/sekai/e1.html#s010103)
            </p>
          </div>
        </div>
        <hr />
        <div className={styles.contents}>
          <h2 className={styles.title}>人口比較チャート</h2>
          <div className={styles.chart}>
            <div className={styles.sampleView}>
              <Chart
                width={'95%'}
                height={'300px'}
                chartType="BarChart"
                loader={<div>Loading Chart....</div>}
                data={popData}
                options={{
                  title: '1980 - 2050年 人口推移比較(アジア４カ国)',
                  chartArea: { width: '50%' },
                  hAxis: {
                    title: 'Total Population',
                    minValue: 0,
                  },
                  vAxis: {
                    title: 'City',
                  },
                }}
                // For tests
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
            <p>
              アジア4ヵ国の人口を、1980年、2019年、2050年でバーチャートにマッピングしたグラフィックです。
              <br />
              こちらも総務省統計局の世界の推計人口をデータソースとしています。
              <br />
              (参照: https://www.stat.go.jp/data/sekai/e1.html#s010103)
            </p>
          </div>
        </div>
        <hr />
        <div className={styles.contents}>
          <h2 className={styles.title}>日経平均月足チャート</h2>
          <div className={styles.chart}>
            <div className={styles.sampleView}>
              <Chart
                width={'95%'}
                height={300}
                chartType="CandlestickChart"
                loader={<div>Loading Chart....</div>}
                data={stockData}
                // data={[
                //   ['day', 'a', 'b', 'c', 'd'],
                //   ['Mon', 20, 28, 38, 45],
                //   ['Tue', 31, 38, 55, 66],
                //   ['Wed', 50, 55, 77, 80],
                //   ['Thu', 77, 77, 66, 50],
                //   ['Fri', 68, 66, 22, 15],
                // ]}
                options={{
                  title: '月平均株価チャート',
                  legend: 'none',
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
            <p>
              日経平均株価の過去3年間の月足ローソク足チャートです。
              <br />
              日経平均プロファイルの日経平均株価、月次データをソースとしています。
              <br />
              (参照: https://indexes.nikkei.co.jp/nkave/index?type=download)
            </p>
          </div>
        </div>
      </div>
    );
  }
}
