// @flow

import React from 'react';
import { compose } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import styles from './styles.js';

type Props = {
  markers: Array<any>,
};

const CustomStyleMap = compose(
  withScriptjs,
  withGoogleMap
)((props: Props) => (
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 35.672752, lng: 139.768858 }}
    defaultOptions={{
      disableDefaultUI: false, // disable default map UI
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
      styles: styles,
    }}
  />
));

export default CustomStyleMap;
