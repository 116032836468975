// @flow

import React from 'react';
import { compose } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import CustomMarker from './CustomMarker';
import styles from './styles.js';

type Props = {
  markers: Array<any>,
};

const GoogleMapComponentWithMarker = compose(
  withScriptjs,
  withGoogleMap
)((props: Props) => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 34.609906, lng: 135.479222 }}
    defaultOptions={{
      disableDefaultUI: false, // disable default map UI
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
      styles: styles,
    }}
  >
    {props.markers && props.markers.length && (
      <div>
        {props.markers.map((marker, i) => (
          <CustomMarker key={i} marker={marker} />
        ))}
      </div>
    )}
  </GoogleMap>
));

export default GoogleMapComponentWithMarker;
