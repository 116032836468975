import React from 'react';
import throttle from 'lodash.throttle';

const ScrollHoc = WrappedComponent => {
  return class WishScroll extends React.PureComponent {
    constructor() {
      super();
      this.state = {
        pageYOffset: undefined,
        maxHeight: undefined,
      };
    }

    componentDidMount() {
      document.addEventListener('scroll', throttle(this.onScroll), 5000);
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
      const targetElement = document.getElementById('pageRoot');
      // 2000 could be any
      this.setState({ pageYOffset: window.top.pageYOffset, maxHeight: targetElement.clientHeight });
    };

    render() {
      const isDownArrow =
        this.state.pageYOffset < this.state.maxHeight * 0.5
          ? true
          : this.state.pageYOffset > this.state.maxHeight * 0.8
          ? false
          : undefined;
      return (
        isDownArrow !== undefined && <WrappedComponent isDownArrow={isDownArrow} pageYOffset={this.state.pageYOffset} />
      );
    }
  };
};

export default ScrollHoc;
