// @flow

import React from 'react';
import uniq from 'lodash.uniq';
import { MdSend } from 'react-icons/md';
import Breadcrumb from '../../components/breadcrumb';
import Button from '../../components/button';
import Text from '../../components/text';
import SimpleLoader from './simpleLoader';
import styles from './contact.module.scss';

type Props = {
  gmail: Object,
  fetchGmailApi: Function,
};

type State = {
  name: string,
  address: string,
  tel: string,
  budget: string,
  content: string,
  errors: Array<any>,
  postComplete: boolean,
};

export default class ContactPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      postComplete: false,
      name: '',
      address: '',
      tel: '',
      budget: '',
      content: '',
      errors: ['name', 'address', 'tel', 'budget', 'content'],
    };
  }

  handleOnBlur = (e: any) => {
    const key = e.target.name;
    const val = e.target.value;
    const obj = {};
    obj[key] = val;
    this.setState(obj);
  };

  handleOnSubmit = () => {
    const { name, address, tel, budget, content } = this.state;
    const postData = {
      name,
      address,
      tel,
      budget,
      content,
    };
    this.props.fetchGmailApi(name, address, tel, budget, content, this.submitCallback);
    console.log('post', postData);
  };

  submitCallback = (fetchStatus: boolean) => {
    if (fetchStatus) {
      this.setState({
        postComplete: true,
      });
    } else {
      this.setState({
        postComplete: false,
      });
    }
  };

  registError = (name: any) => {
    this.setState(prevState => {
      const currentError = prevState.errors;
      return {
        errors: uniq([...currentError, name]),
      };
    });
  };

  unregistError = (name: any) => {
    this.setState(prevState => {
      const currentError = prevState.errors;
      const idx = currentError.findIndex(err => err === name);
      if (idx >= 0) {
        currentError.splice(idx, 1);
        return { errors: currentError };
      }
      return {};
    });
  };

  render() {
    return (
      <div className={styles.contact}>
        <div>
          <div className={styles.breadcrumb}>
            <Breadcrumb type="service" />
          </div>
          <h1 className={styles.mainText}>Please contact us</h1>
          <p className={styles.subText}>
            お問い合わせ頂いてから2 - 3営業日以内に、
            <br />
            弊社担当からご連絡いたします。
          </p>
          {this.state.postComplete ? (
            <div>
              <div className={styles.completeText}>
                送信しました。
                <br />
                お問い合わせありがとうございます。
              </div>
              <div className={styles.completeSvg}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle
                    className={`${styles.path} ${styles.circle}`}
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className={`${styles.path} ${styles.check}`}
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div className={this.props.gmail.isFetching ? styles.fetching : ''}>
              {this.props.gmail.isFetching && <SimpleLoader />}
              <div className={styles.inputWrapper}>
                <Text
                  label="お名前"
                  id="name"
                  name="name"
                  onBlur={this.handleOnBlur}
                  registError={this.registError}
                  unregistError={this.unregistError}
                  validations={[
                    {
                      rule: 'required',
                      errorMessage: 'お名前を入力して下さい',
                    },
                  ]}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Text
                  label="メールアドレス"
                  id="address"
                  name="address"
                  onBlur={this.handleOnBlur}
                  registError={this.registError}
                  unregistError={this.unregistError}
                  validations={[
                    {
                      rule: 'required',
                      errorMessage: '有効なメールアドレスを入力して下さい',
                    },
                    // {
                    //   rule: 'EMAIL',
                    //   errorMessage: '有効なメールアドレスを入力して下さい'
                    // }
                  ]}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Text
                  label="電話番号"
                  id="tel"
                  name="tel"
                  onBlur={this.handleOnBlur}
                  registError={this.registError}
                  unregistError={this.unregistError}
                  validations={[
                    {
                      rule: 'required',
                      errorMessage: '電話番号を入力して下さい',
                    },
                  ]}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Text
                  label="ご予算"
                  id="budget"
                  name="budget"
                  onBlur={this.handleOnBlur}
                  registError={this.registError}
                  unregistError={this.unregistError}
                  validations={[
                    {
                      rule: 'required',
                      errorMessage: 'ご予算を入力して下さい',
                    },
                  ]}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Text
                  label="お問い合わせ内容"
                  id="content"
                  name="content"
                  onBlur={this.handleOnBlur}
                  registError={this.registError}
                  unregistError={this.unregistError}
                  textArea
                  validations={[
                    {
                      rule: 'required',
                      errorMessage: 'お問い合わせ内容を入力して下さい',
                    },
                  ]}
                />
              </div>
              <div className={styles.submitButton}>
                <Button
                  label="送信"
                  disable={this.state.errors.length > 0}
                  onClick={this.handleOnSubmit}
                  svgIcon={<MdSend />}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
