// @flow

import ChartPage from '../page/chartPage';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = (/* dispatch */) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartPage);
