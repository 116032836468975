// @flow

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ScrollToTop from '../../utils/scrollToTop';
import MapContainer from '../../containers/map.container.js';
import ChartContainer from '../../containers/chart.container.js';
import HomeContainer from '../../containers/home.container.js';
import PostContainer from '../../containers/post.container.js';
import PostsContainer from '../../containers/posts.container.js';
import ContactContainer from '../../containers/contact.container.js';
import Service from '../../page/service';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import styles from './app.module.scss';

type Props = {};

type State = {
  openSlider: boolean,
};

const routes = [
  { path: '/', name: 'Home', Component: HomeContainer },
  { path: '/chart', name: 'Chart', Component: ChartContainer },
  { path: '/contact', name: 'Contact', Component: ContactContainer },
  { path: '/map', name: 'Map', Component: MapContainer },
  { path: '/post/:id', name: 'Post', Component: PostContainer },
  { path: '/service', name: 'Service', Component: Service },
  { path: '/posts', name: 'Posts', Component: PostsContainer },
];

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openSlider: false,
    };
  }

  onToggleSlider = () => {
    this.setState(({ openSlider }) => ({ openSlider: !openSlider }));
  };

  handleOpenSlider = () => {
    this.setState({ openSlider: true });
  };

  handleCloseSlider = () => {
    this.setState({ openSlider: false });
  };

  render() {
    const classNames = {
      enter: styles.enter,
      enterActive: styles.enterActive,
      exit: styles.exit,
      exitActive: styles.exitActive,
    };

    return (
      <div className={styles.container}>
        <Header onClickMenu={this.onToggleSlider} />
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match, location, history }) => (
              <CSSTransition in={match != null} timeout={1000} classNames={classNames} unmountOnExit>
                <div className={styles.page} id="pageRoot">
                  <ScrollToTop>
                    <Component location={location} match={match} history={history} />
                    {path !== '/' && <Footer />}
                  </ScrollToTop>
                </div>
              </CSSTransition>
            )}
          </Route>
        ))}
        <Sidebar open={this.state.openSlider} handleCloseSlider={this.handleCloseSlider} />
      </div>
    );
  }
}
