// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiFileText, FiPenTool } from 'react-icons/fi';
import styles from './breadcrumb.module.scss';

type Props = {
  type: string,
  name?: string,
};

export default function Breadcrumb(props: Props) {
  const lists = {
    service: [{ name: 'サービス紹介', icon: <FiFileText /> }],
    postList: [{ name: '記事一覧', icon: <FiFileText /> }],
    chart: [{ name: 'サンプルアプリ' }, { name: '図表関連', icon: <FiPenTool /> }],
    map: [{ name: 'サンプルアプリ' }, { name: 'MAP関連', icon: <FiPenTool /> }],
    post: [{ name: '記事一覧', url: '/posts' }, { name: props.name, icon: <FiFileText /> }],
  };

  const list = lists[props.type];

  return (
    <div className={styles.wrapper}>
      <Link to="/">
        <div className={styles.text}>
          {/* <FiHome /> */}
          HOME
        </div>
      </Link>
      {list.map((li, i) => {
        if (li.url) {
          return (
            <div className={styles.textWithIcon}>
              <i className={styles.iconArrow}>
                <FiChevronRight />
              </i>
              <Link to={li.url}>
                {li.icon && <i className={styles.iconItems}>{li.icon}</i>}
                {li.name}
              </Link>
            </div>
          );
        } else {
          return (
            <div className={styles.textWithIcon}>
              <i className={styles.iconArrow}>
                <FiChevronRight />
              </i>
              {li.icon && <i className={styles.iconItems}>{li.icon}</i>}
              {li.name}
            </div>
          );
        }
      })}
    </div>
  );
}
