// @flow

import React from 'react';
import Button from '../../components/button';
import MoveLoading from '../../components/svg/moveLoading';
import Breadcrumb from '../../components/breadcrumb';
import { CAT } from '../../constants/common';
import styles from './postsPage.module.scss';
import Posts from '../../components/posts';

type Props = {
  fetchCagetories: any,
  fetchPosts: any,
  posts: Array<any>,
  postsTotal: number,
  isLoading: Boolean,
};

type State = {
  loadedPosts: number,
};

export default class PostsPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadedPosts: 0,
    };
  }

  componentDidMount() {
    this.props.fetchPosts(6, 0, CAT.homepage);
    this.setState({
      loadedPosts: 6,
    });
    this.props.fetchCagetories();
  }

  handleOnClickLoading = () => {
    const count = this.state.loadedPosts + 6;
    this.props.fetchPosts(count);
    this.setState({
      loadedPosts: count,
    });
  };

  render() {
    return (
      <div>
        <div className={styles.blogs}>
          <div className={styles.blogsTitleWrapper}>
            <Breadcrumb type="postList" />
            <div className={styles.title}>記事一覧</div>
          </div>
          {this.props.posts && this.props.posts.length > 0 ? (
            <div>
              <Posts posts={this.props.posts} isLoading={this.props.isLoading} />
              {this.props.posts.length < this.props.postsTotal && (
                <div className={styles.blogButtonWrapper}>
                  <Button
                    label="その他の記事も読む"
                    theme="secondary"
                    svgIcon={<MoveLoading />}
                    onClick={this.handleOnClickLoading}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noBlog}>
              <p>コンテンツがありません。</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
