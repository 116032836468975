// @flow

import React from 'react';
import { IoIosMail } from 'react-icons/io';
import ReadMore from '../../components/readMore';
import Button from '../../components/button';
import MoveLoading from '../../components/svg/moveLoading';
import styles from './home.module.scss';
import PostsList from '../../components/postsList';
import Particle from '../../components/particle';
import Canvas from '../../components/canvas';
import Images from '../../components/images';
import Scroll from '../../components/scroll';
import MovingGrid from '../../components/movingGrid';
import MetaTags from '../../components/metaTags';

type Props = {
  fetchCagetories: any,
  fetchPosts: any,
  posts: Array<any>,
  postsTotal: number,
};

type State = {
  contactHeight: number,
  loadedPosts: number,
  showTopImg: boolean,
};

export default class Home extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.contactRef = React.createRef();
    this.canvasRef = React.createRef();
    this.canvasWrapperRef = React.createRef();
    this.state = {
      contactHeight: 0,
      loadedPosts: 0,
      showTopImg: true,
    };
  }

  contactRef: any;
  canvasRef: any;
  canvasWrapperRef: any;

  componentDidMount() {
    this.props.fetchPosts(3, 0);
    this.setState({
      loadedPosts: 3,
    });
    this.props.fetchCagetories();
    this.getHeight();
  }

  getHeight = () => {
    const { clientHeight } = this.contactRef.current;
    this.setState({
      contactHeight: clientHeight,
    });
  };

  handleOnClickLoading = () => {
    const count = this.state.loadedPosts + 6;
    this.props.fetchPosts(count);
    this.setState({
      loadedPosts: count,
    });
  };

  render() {
    return (
      // <div style={{ marginBottom: this.state.contactHeight }}>
      <div>
        <MetaTags keywords="Web制作　ホームページ　デザイン" />
        <div className={styles.topWrapper}>
          <Scroll />
          <MovingGrid />
          <div className={styles.canvasWrapper}>
            <span className={styles.canvaslabel}>Scratchable here</span>
            <Canvas />
          </div>
          <div className={styles.flex50}>
            <div className={styles.contentsLeft}>
              <p className={styles.subText}>WHAT WE THINK</p>
              <h2 className={`${styles.subText} ${styles.h2}`}>ホームページとブランディング</h2>
              <p>ホームページを作成する目的は何でしょうか？</p>
              <p>
                もちろんビジネスの規模によって、目的は様々だと思います。例えば、有名な大企業であれば自社のサービスや、社会への貢献を紹介するものかもしれません。また、起業したばかりのスタートアップ企業であれば自社を知ってもらうための名刺代わりだったりするのではないでしょうか。
              </p>
              <p>
                ホームページは、お客様との最初のタッチポイントであり、お客様を伝えたい情報に適切に誘導し、分かりやすい表現で的確に伝えるという目的を持っています。
              </p>
              <p>
                我々は、例え数分程度の短い滞在時間のお客様に対しても高品質かつ、きめ細かなサービスで、御社様の企業イメージに合わせた最高のUX(ユーザー体験)をご提供出来るような、上質なホームページ(或いはECサイト)を作って行きたいと考えています。
              </p>
              <ReadMore pathname="/posts" textWhite />
            </div>
            <div className={styles.contentsRight} />
            <div />
          </div>
          <div className={styles.serviceContents}>
            <Particle />
            <p className={styles.subText}>WHAT WE DO</p>
            <h2 className={`${styles.itemText} ${styles.h2}`}>我々が提供できるサービス</h2>
            <div className={`${styles.flex50} ${styles.serviceItemWrapper}`}>
              <div className={styles.serviceItem}>
                <div className={styles.iconImage}>
                  <Images src="icon-ui.svg" />
                </div>
                <p className={styles.serviceItemTitle}>UI / UXコンサルティング</p>
                <p>デザインコンセプトの作成</p>
                <p>ユーザー要件の調査・定義</p>
                <p>UI/UX改善提案等</p>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.iconImage}>
                  <Images src="icon-design.svg" />
                  {/* <img className={styles.iconDesign} src="static/img/icon-design.svg" alt="designIcon" /> */}
                </div>
                <p className={styles.serviceItemTitle}>デザイン</p>
                <p>ロゴ制作</p>
                <p>名刺・印刷物のデザイン</p>
                <p>ホームページデザイン</p>
              </div>
              <div className={styles.serviceItem}>
                <div className={styles.iconImage}>
                  <Images src="icon-website.svg" />
                  {/* <img className={styles.iconWeb} src="static/img/icon-website.svg" alt="websiteIcon" /> */}
                </div>
                <p className={styles.serviceItemTitle}>ホームページ開発</p>
                <p>Wordpressテーマの制作</p>
                <p>ECサイト、SPAサイトの制作</p>
                <p>HTML/CSS/JSコーディング</p>
              </div>
            </div>
          </div>
          <div className={styles.flex50R}>
            <div className={styles.contentsLeft2} />
            <div className={styles.contentsRight2}>
              <p className={styles.subText}>BEST PRACTICE</p>
              <h2 className={`${styles.subText} ${styles.h2}`}>ホームページ作成の事前準備</h2>
              <p>効率よくホームページ(以下サイト)を作るためには、事前に準備しておくべきことがあります。</p>
              <p>
                全くはじめての方の場合、何から手をつけて良いのか検討もつかないという方もいらっしゃると思います。
                最低限必要な情報をまとめておくことで、外部業者に依頼する際にも無駄なコミュニケーションや工数を削減することができます。
              </p>
              <p>
                まずは目的地を設定しましょう。
                <br />
                そのサイトに訪れたお客様に対して最終的に何を達成していただきたいのかが目的地となります。これを「コンバージョン」という言葉で表現します。
                <br />
                目標となるコンバージョンを設定することで、そのホームページをどのように作るかの方向性や、掲載するコンテンツのアイデアが生まれるのです。
              </p>
              <p>
                コンバージョンの具体的な例は様々です。
                <br />
                - 商品やサービスについてのお問い合わせ
                <br />
                - 商品やサービスの購入
                <br />
                - イベントへの参加申し込み
                <br />
              </p>
              <p>
                コンバージョンを設定する事で、これから考えるページ構成や、レイアウト、文章・画像コンテンツといった、サイト作成に必要な要素も、より具体的にイメージ出来るようにるのではないか思います。
              </p>
              <ReadMore pathname="/posts" />
            </div>
            <div />
          </div>
          <div className={styles.blogs}>
            <div className={styles.blogsTitleWrapper}>
              <p className={styles.subText}>NEW CONTENTS</p>
              <h2 className={`${styles.h2}`}>最近の記事</h2>
            </div>
            {this.props.posts && this.props.posts.length > 0 ? (
              <div>
                <PostsList posts={this.props.posts} />
                {this.props.posts.length < this.props.postsTotal && (
                  <div className={styles.blogButtonWrapper}>
                    <Button
                      label="その他の記事も読む"
                      theme="secondary"
                      // imgSrc="static/img/icon-spinner.svg"
                      svgIcon={<MoveLoading />}
                      onClick={this.handleOnClickLoading}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.noBlog}>
                <p>コンテンツがありません。</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.canvasWrapper}>
          <span className={styles.canvaslabel}>Scratch canvas</span>
          <Canvas />
        </div>
        <div className={styles.contact} ref={this.contactRef}>
          <div>
            <p className={styles.subText}>LET’S THINK TOGETHER</p>
            <h2 className={`${styles.subText} ${styles.h2}`}>
              費用対効果の高く、美しいホームページで
              <br />
              あなたのビジネスを成長させましょう。
              <br />
              まずはご相談ください。
            </h2>
            <Button label="お問い合わせフォームへ" svgIcon={<IoIosMail size="50" />} to="/contact" />
          </div>
        </div>
      </div>
    );
  }
}
