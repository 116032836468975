// @flow

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withSize } from 'react-sizeme';
import { Link } from 'react-router-dom';
import { getMqType } from '../../utils/layoutControl';
import PostsSkeleton from '../loading/postsSkeleton';
import styles from './posts.module.scss';

type Props = {
  posts: Array<any>,
  isLoading: Boolean,
  size: {
    width: number,
    height: number,
  },
};

type State = {
  imgHeight: number,
  imgLoaded: boolean,
};

export class Posts extends React.Component<Props, State> {
  static defaultProps = {
    isLoading: true,
  };
  constructor(props: Props) {
    super(props);
    this.imgRef = React.createRef();
    this.state = {
      imgHeight: 200,
      imgLoaded: false,
    };
  }

  imgRef: any;

  componentDidMount() {
    this.heightControl();
    this.setState({
      imgLoaded: true,
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.size.width !== this.props.size.width) {
      setTimeout(() => {
        this.heightControl();
      }, 10);
    }
  }

  heightControl = () => {
    const clientWidth =
      this.imgRef && this.imgRef.current && this.imgRef.current.clientWidth ? this.imgRef.current.clientWidth : null;
    // const { clientWidth } = this.imgRef.current;

    this.setState({
      imgHeight: clientWidth ? (clientWidth / 4) * 3 : 100,
    });
  };

  render() {
    const loadingCount = [0, 0, 0, 0, 0, 0];
    const contentsMqType = getMqType(this.props.size);
    const respStyle = contentsMqType === 'SP' ? styles.SP : contentsMqType === 'TL' ? styles.TL : styles.PC;
    const list =
      this.props.isLoading && !this.state.imgLoaded
        ? loadingCount.map((l, index) => (
            <div key={index} className={styles.postItem}>
              <div ref={this.imgRef}>
                <PostsSkeleton height={this.state.imgHeight} />
              </div>
            </div>
          ))
        : this.props.posts.map(p => (
            <div key={p.id} className={styles.postItem}>
              <Link to={`post/${p.id}`}>
                <div className={styles.img} style={{ height: this.state.imgHeight }} ref={this.imgRef}>
                  {p.media ? <img src={p.media.medium_large || p.media.medium || p.media.thumbnail} /> : null}
                </div>
                <div className={styles.title}> {ReactHtmlParser(p.title)} </div>
                <div className={styles.excerpt}> {ReactHtmlParser(p.excerpt)} </div>
              </Link>
              <div className={styles.cat}>{p.categoriesName}</div>
            </div>
          ));
    return (
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.inner}  ${respStyle}`}>{list}</div>
      </div>
    );
  }
}

export default withSize()(Posts);
