// @flow

import PostPage from '../page/postPage';
import { connect } from 'react-redux';
import { fetchPost } from '../actions/wpControl';

const mapStateToProps = state => ({
  post: state.posts.data,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPost: () => dispatch(fetchPost(ownProps.match.params.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostPage);
