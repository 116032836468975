import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import AppContainer from './containers/app.container.js';
import configureStore from './common/store';
import './common/styles/base.scss';
import './common/styles/custom.scss';
// import registerServiceWorker from './registerServiceWorker';
ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <Route path="/" component={AppContainer} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
// registerServiceWorker();
