// @flow

type Size = {
  width: number,
  height: number,
};

export function getMqType(size: Size = { width: 0, height: 0 }) {
  return size.width < 744 ? 'SP' : size.width < 1025 ? 'TL' : 'PC';
}
