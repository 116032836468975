// @flow

import Map from '../page/map';
import { connect } from 'react-redux';
import { simpleAction } from '../actions/simpleAction';
import { fetchPrefectures } from '../actions/fetchPrefectures';
import markers from '../datas/pref.json';

const mapStateToProps = state => ({
  ...state,
  markers: markers,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
  fetchPrefectures: () => dispatch(fetchPrefectures()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
