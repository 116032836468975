// @flow

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withSize } from 'react-sizeme';
import { Link } from 'react-router-dom';
import { getMqType } from '../../utils/layoutControl';
import styles from './postsList.module.scss';

type Props = {
  posts: Array<any>,
  size: {
    width: number,
    height: number,
  },
};

type State = {
  imgHeight: number,
};

export class PostsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.imgRef = React.createRef();
    this.state = {
      imgHeight: 200,
    };
  }

  imgRef: any;

  componentDidMount() {
    this.heightControl();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.size.width !== this.props.size.width) {
      setTimeout(() => {
        this.heightControl();
      }, 10);
    }
  }

  heightControl = () => {
    const clientWidth =
      this.imgRef && this.imgRef.current && this.imgRef.current.clientWidth ? this.imgRef.current.clientWidth : null;
    // const { clientWidth } = this.imgRef.current;

    this.setState({
      imgHeight: clientWidth ? (clientWidth / 4) * 3 : 100,
    });
  };

  render() {
    const contentsMqType = getMqType(this.props.size);
    const respStyle = contentsMqType === 'SP' ? styles.SP : contentsMqType === 'TL' ? styles.TL : styles.PC;
    const list = this.props.posts.map(p => (
      <div key={p.id} className={styles.postItem}>
        <Link to={`post/${p.id}`}>
          <div className={styles.img} style={{ height: this.state.imgHeight }} ref={this.imgRef}>
            {p.media ? <img src={p.media.medium_large || p.media.medium || p.media.thumbnail} /> : null}
          </div>
          <div className={styles.title}> {ReactHtmlParser(p.title)} </div>
          <div className={styles.excerpt}> {ReactHtmlParser(p.excerpt)} </div>
        </Link>
        <div className={styles.cat}>{p.categoriesName}</div>
      </div>
    ));
    return <div className={`${styles.wrapper} ${respStyle}`}> {list} </div>;
  }
}

export default withSize()(PostsList);
