// @flow
//Libraries
import { createSelector } from 'reselect';

const getPosts = state => state.posts.items || null;
const getMedias = state => (state.medias && state.medias.items) || null;
const getCategories = state => (state.categories && state.categories.items) || null;

export const getPostsList = createSelector(
  [getPosts, getMedias, getCategories],
  (posts, medias, categories) => {
    if (medias || categories) {
      return posts.map(p => {
        const targetMedia = medias ? medias.find(m => m.id === p.id) : null;
        const targetCategories = categories ? categories.find(cat => cat.id === p.categories[0]) : null;
        const newPosts = Object.assign(
          { ...p },
          { categoriesName: targetCategories ? targetCategories.name : null },
          { media: targetMedia ? targetMedia.medias : null }
        );
        return newPosts;
      });
    } else return posts;
  }
);
