import React from 'react';
import { Link } from 'react-router-dom';
import Images from '../images';
import styles from './footer.module.scss';

export default function Footer() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.links}>
        <Link to="/">Home</Link>
        <Link to="/service">サービス紹介</Link>
        <Link to="/posts">記事一覧</Link>
        <Link to="/contact">お問い合わせ</Link>
      </div>
      <div className={styles.logo}>
        <Images src="logo_yumehana.svg" />
      </div>
    </div>
  );
}
