import axios from 'axios';

const url = process.env.REACT_APP_RESAS_API;
const key = process.env.REACT_APP_RESAS_KEY;

export const fetchPrefectures = () => dispatch => {
  console.log(url);
  axios
    .get(`${url}/v1/prefectures`, {
      headers: { 'X-API-KEY': key },
    })
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'FETCH_PREFECTURES_SUCCESS',
          result: res,
        });
      } else {
        dispatch({
          type: 'FETCH_PREFECTURES_ERROR',
          result: res,
        });
      }
    })
    .catch(error => {
      console.log('Error', error);
    });
};
