// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import IconMenu from '../svg/iconMenu';
import Images from '../images';
import styles from './header.module.scss';

type Props = {
  onClickMenu: Function,
};

type State = {
  onMenu: boolean,
};

export default class Header extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      onMenu: false,
    };
  }

  onMouseEnter = () => {
    this.setState({
      onMenu: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      onMenu: false,
    });
  };

  render() {
    return (
      <header id="header">
        <div className={styles.headerWrapper}>
          <div className={styles.headerInner}>
            <div className={styles.logoArea}>
              <Link to={'/'}>
                <div href className={styles.logo}>
                  <Images src="logo_yumehana.svg" />
                </div>
              </Link>
              <div className={styles.logoText}>江東区有明のWeb制作会社</div>
            </div>
            <a
              href
              className={styles.menuArea}
              onClick={this.props.onClickMenu}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              Menu
              <IconMenu onHover={this.state.onMenu} />
            </a>
          </div>
        </div>
      </header>
    );
  }
}
