// @flow

import React from 'react';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import styles from './movingGrid.module.scss';

type Props = {};

type State = {
  styleMode: string,
  showBox: boolean,
  showImg: boolean,
};

export default class MovingGrid extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      styleMode: 'default',
      showBox: true,
      showImg: true,
    };
    this.lists = {
      default: {
        title: 'Design & coding',
        text:
          'デザイナー出身のWeb開発者達によるコーディング代行業務をご提供しています。美しいデザインと、インタラクティブな表現を、保守性の高いコーディングにてご提供します。',
        ctrl: 'HTML/CSS/Javascript',
        class: styles.imgDefault,
      },
      center: {
        title: 'Single Page App',
        text:
          'より高度Webアプリケーション開発のために、Single Page Applicationのコーディングを代行いたします。カスタムコンポーネントの作成、Server Side Rendering実装、Webpackの導入やCypress等のe2e Testing toolの導入等、最新のFrontend技術に沿ったコーディングを代行いたします',
        ctrl: 'React/node.js/Next.js',
        class: styles.imgCenter,
      },
      right: {
        title: 'Wordpressの活用',
        text:
          'Wordpressのテンプレート作成、カスタマイズ、運用。静的HTMLページのCMS化、Wordpress設置、レンタルサーバーの手続き代行など、面倒な作業もすべてまとめてお任せください。',
        ctrl: 'Wordpress/PHP',
        class: styles.imgRight,
      },
    };
    this.timer = undefined;
  }

  lists: any;
  timer: any;

  componentDidMount() {
    this.handleTimer();
  }

  handleTimer = (obj: any = { reset: true }) => {
    if (obj.reset) {
      clearInterval(this.timer);
      this.timer = setInterval(this.autoSlideRight, 5000);
      setTimeout(() => clearInterval(this.timer), 15000);
    } else {
      this.timer = setInterval(this.autoSlideRight, 5000);
      setTimeout(() => clearInterval(this.timer), 15000);
    }
  };

  handleShowBox = () => {
    this.setState(
      {
        showBox: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showBox: true,
          });
        }, 600);
      }
    );
  };

  handleShowImg = () => {
    this.setState(
      {
        showImg: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showImg: true,
          });
        }, 500);
      }
    );
  };

  handleStyleMode = (obj: any = { reverse: false }) => {
    const modes = Object.keys(this.lists);
    const idx = modes.findIndex(m => m === this.state.styleMode);
    let setIndex;
    if (obj.reverse === true) {
      setIndex = idx === 0 ? modes.length - 1 : idx - 1;
    } else {
      setIndex = idx === modes.length - 1 ? 0 : idx + 1;
    }
    this.setState({
      styleMode: modes[setIndex],
    });
  };

  handleClickLeft = () => {
    this.handleShowImg();
    this.handleShowBox();
    this.handleTimer({ reset: true });
    this.handleStyleMode({ reverse: true });
  };

  handleClickRight = () => {
    this.handleShowImg();
    this.handleShowBox();
    this.handleTimer({ reset: true });
    this.handleStyleMode();
  };

  autoSlideRight = () => {
    this.handleShowImg();
    this.handleShowBox();
    this.handleStyleMode();
  };

  render() {
    const movingStyle = {
      default: styles.default,
      center: styles.center,
      right: styles.right,
    };

    return (
      <div className={styles.mainImg}>
        <div className={`${styles.verticalLines} ${movingStyle[this.state.styleMode]}`} />
        <div className={`${styles.horizontalLinesTop} ${movingStyle[this.state.styleMode]}`} />
        <div className={`${styles.horizontalLinesBottom} ${movingStyle[this.state.styleMode]}`} />
        <div
          className={`${styles.movingBox} ${movingStyle[this.state.styleMode]} ${
            this.state.showBox ? styles.showBox : ''
          }`}
        >
          <div className={styles.movingTextBox}>
            <h1 className={styles.h1}>{this.lists[this.state.styleMode].title}</h1>
            <p className={styles.subTitle}>{this.lists[this.state.styleMode].text}</p>
          </div>
          <div className={styles.movingController}>
            {this.lists[this.state.styleMode].ctrl}
            <div className={styles.iconArrow}>
              <a onClick={this.handleClickLeft}>
                <IoIosArrowRoundBack />
              </a>
              {/* <spsn>{this.timer ? 'onTimer' : 'none'}</spsn> */}
              <a onClick={this.handleClickRight}>
                <IoIosArrowRoundForward />
              </a>
            </div>
          </div>
        </div>
        {/* <div
          className={`${styles.img} ${text[this.state.styleMode].class} ${
            this.state.showImg ? styles.showImg : styles.hideImg
          }`}
        /> */}
        <BackgroundImg lists={this.lists} styleMode={this.state.styleMode} />
      </div>
    );
  }
}

type imgLists = Object;

const BackgroundImg = (props: { lists: imgLists, styleMode: string }) => {
  const elements = [];
  Object.keys(props.lists).forEach((key, i) => {
    const l = props.lists[key];
    elements.push(
      <div
        key={String(i)}
        className={`${styles.img} ${l.class} ${key === props.styleMode ? styles.showImg : styles.hideImg}`}
      />
    );
  });

  return <div>{elements}</div>;
};
