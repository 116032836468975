import axios from 'axios';

const url = process.env.REACT_APP_GMAIL_API;

export const fetchGmailApi = (name, address, tel, budget, content, callback) => dispatch => {
  console.log('fetch-----', name, address, tel, budget, content, `${url}`);
  dispatch({
    type: 'FETCH_GMAIL_BEGIN',
  });
  axios
    .get(`${url}`, {
      params: {
        name,
        address,
        tel,
        budget,
        content,
      },
    })
    .then(res => {
      console.log('res--', res);
      if (res.status === 200) {
        dispatch({
          type: 'FETCH_GMAIL_SUCCESS',
          result: res,
        });
        callback(true);
      } else {
        dispatch({
          type: 'FETCH_GMAIL_ERROR',
          result: res,
        });
        callback(false);
      }
    })
    .catch(error => {
      console.log('Error fetch gmail', error);
      dispatch({
        type: 'FETCH_GMAIL_ERROR',
        result: error,
      });
      callback(false);
    });
};
