// @flow

import PostsPage from '../page/postsPage';
import { connect } from 'react-redux';
import { getPostsList } from '../selectors/posts';
import { CAT } from '../constants/common';
import { fetchCagetories, fetchPosts } from '../actions/wpControl';

type DispatchProps = {};

export function mapStateToProps(state: any /*  ownProps: Prop */) {
  return {
    posts: (state.posts && getPostsList(state)) || null,
    postsTotal: state.posts.total,
    isLoading: state.posts.isFetching,
  };
}

export function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    fetchCagetories: (perPage, offset) => {
      dispatch(fetchCagetories(perPage, offset));
    },
    fetchPosts: (perPage, offset, categories = CAT.homepage) => {
      dispatch(fetchPosts(perPage, offset, categories));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsPage);
