// @flow
import axios from 'axios';
import APIs from '../constants/api';

export function fetchCagetories(per_page: ?number = 100, offset: ?number = 0) {
  return (dispatch: (action: any) => void) => {
    dispatch({ type: 'FETCH_CATEGORIES_BEGIN' });
    axios
      .get(APIs.categories, {
        params: {
          per_page,
          offset,
        },
      })
      .then(res => {
        return res.data.map(r => {
          return {
            id: r.id,
            name: r.name,
            slug: r.slug,
            link: r.link,
            description: r.description,
          };
        });
      })
      .then(categories => {
        dispatch({ type: 'FETCH_CATEGORIES_SUCCESS', response: categories });
      })
      .catch(error => {
        dispatch({ type: 'FETCH_CATEGORIES_ERROR', response: error });
      });
  };
}

export function fetchPosts(per_page: ?number = 100, offset: ?number = 0, categories: ?number = undefined) {
  const params = categories
    ? {
        per_page,
        offset,
        categories,
      }
    : {
        per_page,
        offset,
      };
  return (dispatch: (action: any) => void) => {
    dispatch({ type: 'FETCH_POSTS_BEGIN' });
    axios
      .get(APIs.posts, {
        params,
      })
      .then(res => {
        dispatch({ type: 'FETCH_POSTS_TOTAL', response: Number(res.headers['x-wp-total']) });
        // fetch Images based on response
        dispatch(fetchImages(res.data));
        return res.data.map(r => {
          return {
            id: r.id,
            title: r.title.rendered,
            content: r.content.rendered,
            excerpt: r.excerpt.rendered,
            date: r.date,
            modified: r.modified,
            categories: r.categories,
          };
        });
      })
      .then(posts => {
        dispatch({ type: 'FETCH_POSTS_SUCCESS', response: posts });
      })
      .catch(error => {
        dispatch({ type: 'FETCH_POSTS_ERROR', response: error });
      });
  };
}

export function fetchImages(posts: Array<any>) {
  return (dispatch: (action: any) => void) => {
    const postsWithMediaLink = [];
    const promises = [];
    posts.map(p => {
      const promiseFunction = new Promise((resolve, reject) => {
        const mediaLink = p['_links']['wp:featuredmedia'] ? p['_links']['wp:featuredmedia'][0]['href'] : null;
        if (!mediaLink) {
          resolve();
        }
        return axios
          .get(mediaLink)
          .then(res => {
            const mediaSizes = res.data['media_details']['sizes'];
            const medias = {};
            Object.keys(mediaSizes).forEach(key => {
              medias[key] = mediaSizes[key]['source_url'];
            });
            postsWithMediaLink.push({
              id: p.id,
              medias,
            });
            resolve();
          })
          .catch(error => reject(error));
      });

      return promises.push(promiseFunction);
    });

    Promise.all(promises).then(() => {
      dispatch({ type: 'FETCH_MEDIA_SUCCESS', response: postsWithMediaLink });
    });
  };
}

export function fetchPost(id: number) {
  return (dispatch: (action: any) => void) => {
    dispatch({ type: 'FETCH_POST_BEGIN' });
    axios
      .get(`${APIs.posts}${id}`, {})
      .then(res => {
        const post = {
          id: res.data.id || undefined,
          title: res.data.title.rendered || undefined,
          content: res.data.content.rendered || undefined,
          excerpt: res.data.excerpt.rendered || undefined,
          date: res.data.date || undefined,
          modified: res.data.modified || undefined,
          categories: res.data.categories || undefined,
        };
        dispatch({ type: 'FETCH_POST_SUCCESS', response: post });
      })
      .catch(error => {
        console.log('err', error);
        dispatch({ type: 'FETCH_POST_ERROR', response: error });
      });
  };
}
