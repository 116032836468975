// @flow

import App from '../components/app';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = (/*dispatch*/) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
