// @flow

import React from 'react';
import styles from './images.module.scss';

type Props = {
  src: string,
};

export default function Images(props: Props) {
  return (
    <div className={styles.wrapper}>
      <img src={require(`../../common/images/${props.src}`)} alt={`{${props.src}`} />
    </div>
  );
}
