// @flow

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import IconCross from '../svg/iconCross';
import Accordion from '../accordion';
import styles from './sidebar.module.scss';

type Props = {
  handleCloseSlider: Function,
  open: boolean,
};

type State = {
  slideFadeIn: boolean,
  acOpen: boolean,
  showStoker: boolean,
  xPos: number | typeof undefined,
  yPos: number | typeof undefined,
};

export default class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.dimRef = React.createRef();
    this.stokerRef = React.createRef();
    this.state = {
      slideFadeIn: false,
      showStoker: false,
      acOpen: false,
      xPos: undefined,
      yPos: undefined,
    };
  }

  dimRef: any;
  stokerRef: any;

  onClickAc = () => {
    this.setState({
      acOpen: !this.state.acOpen,
    });
  };

  onHandleCloseSlider = () => {
    this.setState(
      {
        slideFadeIn: false,
      },
      () => setTimeout(() => this.props.handleCloseSlider(), 1000)
    );
  };

  render() {
    const menus = [
      {
        name: 'Home',
        path: '/',
      },
      {
        name: 'サービス紹介',
        path: '/service',
      },
      {
        name: 'サンプルアプリ',
        path: '/works',
        subMenus: [
          {
            name: '図表関連',
            path: '/chart',
          },
          {
            name: 'Map関連',
            path: '/map',
          },
        ],
      },
      {
        name: '記事一覧',
        path: '/posts',
      },
      {
        name: 'お問い合わせ',
        path: '/contact',
      },
    ];

    const classNames = {
      enter: styles.enter,
      enterActive: styles.enterActive,
      exit: styles.exit,
      exitActive: styles.exitActive,
    };

    if (this.state.slideFadeIn) {
      this.dimRef.current.addEventListener('mousemove', e => {
        this.stokerRef.current.style.left = `${e.clientX - 10}px`;
        this.stokerRef.current.style.top = `${e.clientY - 10}px`;
      });
      this.dimRef.current.addEventListener('mouseenter', () => {
        this.setState({
          showStoker: true,
        });
      });
      this.dimRef.current.addEventListener('mouseleave', () => {
        this.setState({
          showStoker: false,
        });
      });
    }

    const getSubmenu = subMenus =>
      subMenus.map(subM => {
        return (
          <li
            key={subM.name}
            className={`${styles.subMenuList} ${this.state.slideFadeIn ? styles.slideFadeIn : styles.slideFadeOut}`}
          >
            <Link to={subM.path} onClick={this.onHandleCloseSlider}>
              {subM.name}
            </Link>
          </li>
        );
      });

    const menuRendered = menus.map(m => {
      return (
        <li
          key={m.name}
          className={`${styles.li} ${this.state.slideFadeIn ? styles.slideFadeIn : styles.slideFadeOut}`}
        >
          {m.subMenus ? (
            <div className={styles.subMenu}>
              <a href onClick={this.onClickAc}>
                {m.name}
              </a>
              <Accordion open={this.state.acOpen}>{getSubmenu(m.subMenus)}</Accordion>
            </div>
          ) : (
            <Link to={m.path} onClick={this.onHandleCloseSlider}>
              {m.name}
            </Link>
          )}
        </li>
      );
    });

    return (
      <div>
        {this.props.open ? (
          <a href className={styles.sidebarDim} onClick={this.onHandleCloseSlider} ref={this.dimRef}>
            &nbsp;
            <i
              ref={this.stokerRef}
              className={`${styles.stoker} ${this.state.showStoker ? styles.showStoker : ''}`}
              styles={{ left: this.state.xPos, top: this.state.yPos }}
            >
              <IconCross disableHover />
            </i>
          </a>
        ) : null}
        <CSSTransition
          classNames={classNames}
          in={this.props.open}
          timeout={300}
          unmountOnExit
          onEntered={() => {
            this.setState({
              slideFadeIn: true,
            });
          }}
        >
          <nav className={`${styles.navWrapper}`}>
            <div className={styles.nav}>
              <div className={styles.crossIcon}>
                <a href onClick={this.onHandleCloseSlider}>
                  <IconCross />
                </a>
              </div>
              <div>{menuRendered}</div>
            </div>
          </nav>
        </CSSTransition>
      </div>
    );
  }
}
