// @flow

import React from 'react';
import styles from './scroll.module.scss';
import { scrollTo } from '../../utils/animatedScrollTo';
import ScrollHoc from '../scrollHoc';

type Props = {
  isDownArrow: boolean,
  pageYOffset: number,
};

type State = {
  downArrowAnimationIn: boolean,
  downArrow: boolean,
};

class Scroll extends React.PureComponent<Props, State> {
  static defaultProps = {
    isDownArrow: true,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      downArrowAnimationIn: true,
      downArrow: true,
    };
  }

  componentDidMount() {
    this.handleAnimateArrow();
  }

  handleAnimateArrow = () => {
    this.setState({
      downArrowAnimationIn: true,
    });
  };

  handleArrowFlip = () => {
    this.setState({
      downArrow: !this.state.downArrow,
    });
  };

  handleScrollTo = () => {
    if (this.props.isDownArrow) {
      scrollTo(this.props.pageYOffset + 1000, 300, () => {});
    } else {
      scrollTo(0, 800, () => {});
    }
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <a
          className={this.props.isDownArrow ? styles.labeldownbar : styles.labelupbar}
          href
          onClick={this.handleScrollTo}
        >
          <div className={styles.label}>Scroll</div>
          <div className={this.props.isDownArrow ? styles.downbar : styles.upbar}>
            <svg width="6px" height="57px" viewBox="0 0 6 57" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none">
                <g id="Artboard" className={this.state.downArrowAnimationIn ? styles.animateIn : ''} stroke="#212121">
                  <g id="DownArrow" transform="translate(7.000000, 5.000000)">
                    <path d="M1.5,0.5 L1.5,56.5" id="Line" />
                    <path d="M6.5,51.5 L1.5,56.5" id="Line" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </a>
      </div>
    );
  }
}

export default ScrollHoc(Scroll);
