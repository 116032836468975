// @flow

import ContactPage from '../page/contactPage';
import { connect } from 'react-redux';
import { fetchGmailApi } from '../actions/fetchGmailAPI';

type DispatchProps = {};

const mapStateToProps = state => ({
  gmail: state.gmail,
});

export function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    fetchGmailApi: (name, address, tel, budget, content, callback) => {
      dispatch(fetchGmailApi(name, address, tel, budget, content, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPage);
