import * as React from 'react';
import Breadcrumb from '../../components/breadcrumb';
import GoogleMapComponentWithMarker from '../../components/maps/gMapWithMarker';
import CustomStyleMap from '../../components/maps/customStyleMap';
import styles from './map.module.scss';

type Props = {
  markers: Array<any>,
  simpleAction: Function,
  fetchPrefectures: Function,
};

// Wrapper with Google Map component
export default class Map extends React.PureComponent<Props> {
  componentDidMount = () => {
    this.props.fetchPrefectures();
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <Breadcrumb type="map" />
          <h1 className={styles.title}>MAP関連アプリ 作成・カスタマイズ</h1>
          <p className={styles.subTitle}>
            ここでご紹介するのは、地図を用いた簡易的なWebアプリケーションのほんの一例です。
            <br />
            GoogleMapなどは、正しくチューニングすることで、デザインの印象を自在にコントロールすることができます。
          </p>
        </div>
        <hr />
        <div className={styles.contents}>
          <h2 className={styles.title}>Google MAP Design</h2>
          <div className={styles.map}>
            <div className={styles.sampleView}>
              <CustomStyleMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
                  process.env.REACT_APP_GOOGLE_MAP_KEY
                }`}
                loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                containerElement={<div style={{ height: `100%`, width: '100%' }} />}
                mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                markers={this.props.markers}
              />
            </div>
            <p>Google MAPはJavaScriptでデザインを自在にアレンジできます。</p>
          </div>
        </div>
        <hr />
        <div className={styles.contents}>
          <h2 className={styles.title}>県庁所在地Map</h2>
          <div className={styles.map}>
            <div className={styles.sampleView}>
              <GoogleMapComponentWithMarker
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
                  process.env.REACT_APP_GOOGLE_MAP_KEY
                }`}
                loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                containerElement={<div style={{ height: `100%`, width: '100%' }} />}
                mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                markers={this.props.markers}
              />
            </div>
            <p>県庁所在地情報をマッピングしたMAPです。SVGアイコンでMarkerをカスタマイズしています。</p>
          </div>
        </div>
      </div>
    );
  }
}
