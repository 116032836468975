// @flow

import React from 'react';
import styles from './postsSkeleton.module.scss';

export default function PostsSkeleton(props: { height: number }) {
  return (
    <div>
      <div className={styles.img} style={{ height: props.height }} />
      <div className={styles.title} />
      <div className={styles.excerpt} />
    </div>
  );
}
