export const COMMON = {
  HPName: 'wordperss-api',
};

export const CAT = {
  mypagetest: 153,
  mypagetestimage: 154,
  homepage: 160,
};

export default COMMON;
