// @flow

import React from 'react';
import styles from './canvas.module.scss';

type Props = {
  height: number,
};

type State = {
  canvasWidth: number,
  key: number,
  isDrawing: boolean,
  startX: number,
  startY: number,
  loaded: boolean,
};

export default class Canvas extends React.Component<Props, State> {
  static defaultProps = {
    height: 70,
  };
  constructor(props: Props) {
    super(props);
    this.canvasRef = React.createRef();
    this.canvasWrapperRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      startX: 0,
      startY: 0,
      // mouseX: 0,
      // mouseY: 0,
      key: 0,
      isDrawing: false,
      loaded: false,
    };
  }

  canvasRef: any;
  canvasWrapperRef: any;

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.onLoad();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getContext() {
    return this.canvasRef.current.getContext('2d');
  }

  onResize = () => {
    // const clientHeight =
    //   (this.canvasWrapperRef && this.canvasWrapperRef.current && this.canvasWrapperRef.current.clientHeight) || 0;
    const clientWidth =
      (this.canvasWrapperRef && this.canvasWrapperRef.current && this.canvasWrapperRef.current.clientWidth) || 0;

    this.setState(
      {
        canvasWidth: clientWidth,
      },
      () => {
        this.initializeCanvas();
      }
    );
  };

  initializeCanvas = () => {
    const ctx = this.getContext();
    ctx.globalCompositeOperation = 'source-over';
    ctx.fillStyle = 'rgba(255, 255, 255, 0.95)';
    ctx.fillRect(0, 0, this.state.canvasWidth, this.props.height);
  };

  resetCanvas = () => {
    this.setState(
      {
        key: Math.random(),
      },
      () => {
        this.initializeCanvas();
      }
    );
  };

  mouseMove = (e: any) => {
    if (this.state.isDrawing) {
      this.drawLine(e);
    } else {
      const mousePos = this.getMousePositon(e);
      this.setState({
        isDrawing: true,
        startX: mousePos.x,
        startY: mousePos.y,
      });
    }
  };

  mouseLeave = () => {
    this.setState({
      isDrawing: false,
    });
    // this.resetCanvas();
  };

  getMousePositon = (e: any) => {
    const rect = this.canvasRef.current.getBoundingClientRect();
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  lineDistance = (point1x: any, point1y: any, point2x: any, point2y: any) => {
    let xs = 0;
    let ys = 0;
    xs = point2x - point1x;
    xs *= xs;
    ys = point2y - point1y;
    ys *= ys;
    return Math.sqrt(xs + ys);
  };

  // controlMouseStatus = (dX, dY) => {
  //   this.setState({
  //     mouseStoped: dX < 3 && dY < 3
  //   });
  // };

  mouseEnter = (e: any) => {
    const mousePos = this.getMousePositon(e);
    this.setState({
      isDrawing: true,
      startX: mousePos.x,
      startY: mousePos.y,
    });
  };

  // mouseUp = () => {
  //   this.setState({
  //     isDrawing: false,
  //   });
  // };

  mouseDown = () => {
    this.setState({
      isDrawing: false,
    });
    this.resetCanvas();
  };

  // mouseDown = (e: any) => {
  //   const mousePos = this.getMousePositon(e);
  //   this.setState({
  //     startX: mousePos.x,
  //     startY: mousePos.y,
  //     isDrawing: true,
  //   });
  // };

  onLoad = () => {
    this.setState({
      loaded: true,
    });
  };

  drawLine = (e: any) => {
    if (this.state.isDrawing) {
      const { startX, startY } = this.state;
      const mousePos = this.getMousePositon(e);
      const mouseX = mousePos.x;
      const mouseY = mousePos.y;
      const dX = mouseX - startX;
      const dY = mouseY - startY;
      // const endX = startX + dX / 10;
      // const endY = startY + dY / 10;
      const endX = startX + dX;
      const endY = startY + dY;
      const distance = this.lineDistance(startX, startY, endX, endY);
      const ctx = this.getContext();
      ctx.globalCompositeOperation = 'destination-out';
      ctx.lineWidth = 1 + parseInt(distance, 10) / 4;
      ctx.lineWidth = 5;
      ctx.lineCap = 'round';
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
      this.setState({
        startX: endX,
        startY: endY,
      });
    }
  };

  // loopAnimation = () => {
  //   requestAnimationFrame(this.loopAnimation);
  //   this.drawLine();
  // };

  render() {
    return (
      <div className={styles.topbgImg} ref={this.canvasWrapperRef}>
        <canvas
          key={this.state.key}
          ref={this.canvasRef}
          width={this.state.canvasWidth || 0}
          height={this.props.height || 0}
          onMouseEnter={e => this.mouseEnter(e)}
          onMouseLeave={() => this.mouseLeave()}
          onMouseMove={e => this.mouseMove(e)}
          onMouseDown={() => this.mouseDown()}
          // onMouseUp={() => this.mouseUp()}
          onLoad={this.onLoad}
        />
      </div>
    );
  }
}
