// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
type Props = {
  title?: string,
  desc?: string,
  keywords?: string,
};

const MetaTags = (props: Props) => {
  return (
    <Helmet>
      {props.title && <title>{props.title}</title>}
      {props.desc && <meta name="description" content={props.desc} />}
      {props.keywords && <meta name="keywords" cpntent={props.keywords} />}
    </Helmet>
  );
};

export default MetaTags;
